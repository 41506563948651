@media screen and (max-width: 576px) {
  .Time {
    top: 80px;
  }
  .Search {
    top: 170px;
    width: 130px;
    padding: 12px 15px;
  }
  .engine {
    top: 250px;
  }
  .input, .Search:hover {
    width: 80%;
    color: black;
    background-color: rgba(255,255,255,0.5);
  }
  .isCover {
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
  }
}
@media screen and (min-width: 600px) {
  .Time {
    top: 115px;
  }
  .Search {
    top: 220px;
    width: 200px;
    padding: 15px 20px;
  }
  .engine {
    top: 300px;
  }
  .input, .Search:hover {
    width: 30%;
    color: black;
    background-color: rgba(255,255,255,0.5);
  }
  .isCover {
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
}
* {
  box-sizing: border-box;
  user-select: none;
}
h1 {
  font-weight: 600;
}
html, body, #root {
  height: 100%;
}
.foucs {
  filter: blur(50px);
}
.App {
  position: relative;
  height: 100%;
  background-color: rgb(51, 51, 51);
}
.bgbox{
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .25s;
  backface-visibility: hidden;
}
.cover {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%),radial-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 200%);
}
.Cover {
  background-image: unset;
}
.Time {
  position: fixed;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-shadow: 0 0 15px rgb(0 0 0 / 30%);
  transition: .5s;
}
.Search {
  position: fixed;
  z-index: 4;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  border: none;
  border-radius: 30px;
  color: rgba(255,255,255,0.8);
  font-size: small;
  font-weight: normal;
  background-color: rgba(255,255,255,.25);
  box-shadow: rgb(0 0 0 / 20%) 0 0 10px;
  backdrop-filter: blur(10px);
  transition: all .5s;
  text-align: center;
}
.Search::placeholder {
  color: white;
}
.engine {
  position: fixed;
  z-index: 4;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  transition: all 1s;
}
.searchOpt {
  display: inline-block;
  position: relative;
  padding: 5px 20px;
  margin: 0 5px;
  width: 80px;
  height: 30px;
  background-color: rgba(255,255,255,.1);
  border-radius: 20px;
  transition: all .5s;
  backdrop-filter: blur(10px); 
}
.selected {
  background-color: rgba(255,255,255,.4);
}
.engine_icon {
  width: 100%;
  height: 100%;
  fill: rgba(255,255,255,0.9);
}
.upload {
  position: absolute;
  top: 21px;
  opacity: 0;
  width: 100%;
  height: 40px;
}
.isCover {
  position: fixed;
  z-index: 3;
  fill: rgba(255,255,255,0.9);
}